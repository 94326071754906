/**
 * @author Thomas S. Butler
 * @abstract Autofill prevention strategies for form inputs
 * @copyright 2022-2024 Firesite LLC
 */

export class AutofillPrevention {
  /**
   * Available prevention strategies
   */
  static strategies = {
    /**
     * Mangles the input name to prevent autofill
     */
    nameMangling: async (input) => {
      // Skip hidden inputs
      if (input.name === "hidden") {
        return;
      }

      // Mangle the input name
      const timestamp = Date.now();
      const origName = input.name;
      input.dataset.originalName = origName;
      await new Promise((resolve) => setTimeout(resolve, 10));
      input.name = `${origName}${timestamp}`;
      input.autocomplete = `new-${origName}${timestamp}`;
      input.setAttribute("data-noautofill", "true");
      input.readOnly = true;
    },

    /**
     * Toggles readonly attribute to prevent autofill
     */
    readonlyToggle: (input) => {
      input.readOnly = true;
      input.addEventListener("focus", () => {
        input.readOnly = false;
      });

      input.addEventListener("blur", () => {
        input.readOnly = true;
      });
    },
  };

  /**
   * Applies prevention strategies to an input
   * @param {HTMLElement} input - The input element
   * @param {Array} strategies - Array of strategy names to apply
   */
  static apply(input, strategies = ["nameMangling", "readonlyToggle"]) {
    // Skip certain input types
    if (input.type === "hidden" || input.type === "submit") {
      return;
    }

    // Skip selectbox and textbox
    if (
      input.classList.contains("selectbox") ||
      input.classList.contains("textbox")
    ) {
      return;
    }

    // Apply each requested strategy
    strategies.forEach((strategy) => {
      if (this.strategies[strategy]) {
        this.strategies[strategy](input);
      }
    });
  }

  /**
   * Restores an input to its original state
   * @param {HTMLElement} input - The input element
   */
  static restore(input) {
    // Skip hidden inputs
    if (input.name === "hidden") {
      return;
    }

    // Restore original name if it was mangled
    if (input.dataset.originalName) {
      input.name = input.dataset.originalName;
      delete input.dataset.originalName;
    }

    // Remove any added attributes
    input.removeAttribute("autocorrect");
    input.removeAttribute("autocapitalize");
    input.removeAttribute("spellcheck");
    input.removeAttribute("data-form-type");

    // Reset styles
    input.style.position = "";
    input.style.left = "";

    // Remove readonly
    input.readOnly = false;
  }
}
