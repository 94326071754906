/**
 * @author Thomas S. Butler
 * @abstract Core form component for the Firesite forms library
 * @copyright 2022-2024 Firesite LLC
 */

import { AutofillPrevention } from "../utils/autofill.js";
import { FormValidation } from "./FormValidation.js";
import { FormInput } from "./FormInput.js";

export class FiresiteForm {
  static defaultConfig = {
    preventAutofill: true,
    validateOnBlur: true,
    validateOnInput: false,
    theme: "light",
    useNativeSelects: false,
  };

  /**
   * Creates a new FiresiteForm instance
   * @param {string|HTMLElement} target - Form element or selector
   * @param {Object} config - Configuration options
   */
  constructor(target, config = {}) {
    this.config = { ...FiresiteForm.defaultConfig, ...config };
    this.element =
      typeof target === "string" ? document.querySelector(target) : target;

    if (!this.element) {
      throw new Error("Invalid form target");
    }

    this.inputs = new Map();
    this.validation = new FormValidation(this);
    this.state = {
      isSubmitting: false,
      isDirty: false,
      isValid: false,
    };
  }

  /**
   * Initializes the form
   */
  init() {
    if (this.config.theme) {
      this.element.dataset.theme = this.config.theme;
    }

    this.setupInputs();
    this.setupEventListeners();
    this.setupAutofillPrevention();

    // Mark as initialized
    this.element.classList.add("fs-form-ready");
  }

  /**
   * Sets up form inputs
   */
  setupInputs() {
    // Ensure form maintains tab context
    this.element.setAttribute("role", "form");
    this.element.setAttribute("tabindex", "-1");

    const inputs = this.element.querySelectorAll("input, select, textarea");
    inputs.forEach((input) => {
      // Skip hidden inputs
      if (input.type === "hidden") return;

      // Create input wrapper if needed
      this.wrapInput(input);

      // Create FormInput instance
      const formInput = new FormInput(input);

      // Store input reference
      this.inputs.set(input.id || input.name, {
        element: input,
        instance: formInput,
        isDirty: false,
        isValid: true,
        value: input.value,
      });

      // Ensure proper tab order
      if (input.type !== "hidden") {
        input.setAttribute("tabindex", "0");
      }
    });

    // Ensure submit button is in tab order when enabled
    const submitButton = this.element.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.setAttribute("tabindex", "0");
    }
  }

  /**
   * Wraps input in control container if needed
   */
  wrapInput(input) {
    if (input.closest(".input-control") || input.name === "hidden") return;

    const wrapper = document.createElement("div");
    wrapper.className = "input-control";
    if (input.classList.contains("selectbox")) {
      wrapper.classList.add("select-container");
    }

    const label = this.element.querySelector(`label[for="${input.id}"]`);
    input.parentNode.insertBefore(wrapper, input);
    wrapper.appendChild(input);
    if (label) wrapper.appendChild(label);

    const line = document.createElement("div");
    line.className = "input-control-line";
    wrapper.appendChild(line);
  }

  /**
   * Sets up form event listeners
   */
  setupEventListeners() {
    // Input events
    this.inputs.forEach(({ element }, key) => {
      element.addEventListener("focus", () => this.handleFocus(key));
      element.addEventListener("blur", () => this.handleBlur(key));
      element.addEventListener("input", () => this.handleInput(key));
      element.addEventListener("change", () => this.handleChange(key));
    });

    // Form events
    this.element.addEventListener("submit", (e) => {
      e.preventDefault();
      e.stopPropagation();
      // Only handle submit if this form is in an active slide
      if (this.element.closest(".form-slide.active")) {
        this.handleSubmit(e);
      }
    });

    // Handle click on submit buttons - only for this specific form
    const submitButton = this.element.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Only handle click if this form is in an active slide
        if (
          this.element.closest(".form-slide.active") &&
          !submitButton.classList.contains("disabled")
        ) {
          this.handleSubmit(e);
        }
      });
    }

    // Handle enter key on form
    this.element.addEventListener("keydown", (e) => {
      // Only handle keydown if this form is in an active slide
      if (!this.element.closest(".form-slide.active")) {
        return;
      }

      if (e.key === "Enter" || e.keyCode === 13) {
        const submitButton = this.element.querySelector(
          'button[type="submit"]'
        );
        if (!submitButton?.classList.contains("disabled")) {
          const activeElement = document.activeElement;
          // Don't submit if we're in a multiline input
          if (activeElement.tagName === "TEXTAREA") {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          this.handleSubmit(e);
        }
      }
    });
  }

  /**
   * Sets up autofill prevention
   */
  setupAutofillPrevention() {
    if (!this.config.preventAutofill) return;

    this.inputs.forEach(({ element }) => {
      AutofillPrevention.apply(element);
    });
  }

  /**
   * Handles input focus
   */
  handleFocus(inputKey) {
    const input = this.inputs.get(inputKey);
    const control = input.element.closest(".input-control");
    if (control) control.classList.add("active");
  }

  /**
   * Handles input blur
   */
  handleBlur(inputKey) {
    const input = this.inputs.get(inputKey);
    const control = input.element.closest(".input-control");

    if (control) {
      control.classList.remove("active");
      if (!input.element.value) {
        control.classList.remove("dirty");
      }
    }

    if (this.config.validateOnBlur) {
      this.validation.validateInput(input.element);
    }
  }

  /**
   * Handles input changes
   */
  handleInput(inputKey) {
    const input = this.inputs.get(inputKey);
    const control = input.element.closest(".input-control");

    if (input.element.value) {
      control.classList.add("dirty");
      input.isDirty = true;
    } else {
      control.classList.remove("dirty");
      input.isDirty = false;
    }

    if (this.config.validateOnInput) {
      this.validation.validateInput(input.element);
    }

    this.state.isDirty = Array.from(this.inputs.values()).some(
      (input) => input.isDirty
    );
  }

  /**
   * Handles input change events
   */
  handleChange(inputKey) {
    const input = this.inputs.get(inputKey);
    input.value = input.element.value;
    this.handleInput(inputKey);
  }

  /**
   * Handles form submission
   */
  async handleSubmit(event) {
    event.preventDefault();

    if (this.state.isSubmitting) return;

    this.state.isSubmitting = true;
    this.element.classList.add("submitting");

    try {
      const isValid = await this.validation.validateAllFields();
      if (!isValid) {
        this.handleValidationError();
        return;
      }

      const data = this.serializeForm();

      // Emit submit event with form data
      const submitEvent = new CustomEvent("fs:submit", {
        detail: { data },
        bubbles: true,
      });
      this.element.dispatchEvent(submitEvent);
    } catch (error) {
      console.error("Form submission error:", error);
      this.handleSubmissionError(error);
    } finally {
      this.state.isSubmitting = false;
      this.element.classList.remove("submitting");
    }
  }

  /**
   * Serializes form data
   */
  serializeForm() {
    const data = {};
    this.inputs.forEach((input, key) => {
      // Use original name from dataset if available, fallback to current name
      const name = input.element.dataset.inputname || input.element.name;
      if (name) {
        data[name] = input.value;
      }
    });
    return data;
  }

  /**
   * Handles validation errors
   */
  handleValidationError() {
    const submitEvent = new CustomEvent("fs:validation-error", {
      detail: { errors: this.validation.errors },
      bubbles: true,
    });
    this.element.dispatchEvent(submitEvent);
  }

  /**
   * Handles submission errors
   */
  handleSubmissionError(error) {
    const submitEvent = new CustomEvent("fs:submit-error", {
      detail: { error },
      bubbles: true,
    });
    this.element.dispatchEvent(submitEvent);
  }

  /**
   * Resets the form
   */
  reset() {
    this.element.reset();
    this.inputs.forEach((input) => {
      input.isDirty = false;
      input.isValid = true;
      input.value = "";
      const control = input.element.closest(".input-control");
      if (control) {
        control.classList.remove("dirty", "invalid", "active");
      }
    });
    this.state.isDirty = false;
    this.state.isValid = false;
    this.validation.reset();
  }
}
